import React from 'react'
import { Link } from "react-router-dom"
import styles from './Footer.module.sass'

function Footer() {
  return <footer className={`${styles.fullwidth} ${styles.centeredcontent} ${styles.footer}`}>
    <div className={`${styles.container}`}>
      Footer
   </div>
  </footer>
}
export default Footer