import React, { useEffect, useState } from 'react'
import { json } from 'react-router-dom'
import styles from './ConversationBehaviours.module.sass'


type ObjectAny = {
  [key: string]: any
}

type Props = {
  socket: WebSocket | null
  sendMessageToWebsocket: (payload: string) => void
  conversationBehaviours: ObjectAny | null
  setConversationBehaviours: React.Dispatch<React.SetStateAction<any>>
  idToken: string | null
  accessToken: string | null
  conversationBehavioursEditStatus: string | null
  setConversationBehavioursEditStatus: React.Dispatch<React.SetStateAction<any>>
}

function ConversationBehaviours({
  socket,
  sendMessageToWebsocket,
  conversationBehaviours,
  setConversationBehaviours,
  idToken,
  accessToken,
  conversationBehavioursEditStatus,
  setConversationBehavioursEditStatus
}: Props) {



  useEffect(() => {
    if (!conversationBehaviours && idToken && accessToken && socket) {
      const payload = JSON.stringify({
        action: "getConversationBehaviours",
        idToken: idToken,
        accessToken: accessToken
      })
      sendMessageToWebsocket(payload)
    }
  }, [conversationBehaviours])


  const handleChange = (value: string, itemState: string, key: string) => {
    if (conversationBehaviours) {
      const newFieldValues: ObjectAny = { ...conversationBehaviours }
      newFieldValues[itemState][key] = value
      setConversationBehaviours(newFieldValues)
    }
  }

  const saveBehaviour = (state: string) => {
    if (conversationBehaviours) {
      setConversationBehavioursEditStatus('saving')
      const payload = JSON.stringify({
        action: "saveConversationBehaviour",
        state: state,
        values: conversationBehaviours[state],
        idToken: idToken,
        accessToken: accessToken
      })
      sendMessageToWebsocket(payload)
    }
  }

  const deleteBehaviour = (state: string) => {
    if (conversationBehaviours) {
      setConversationBehavioursEditStatus('saving')
      const payload = JSON.stringify({
        action: "deleteConversationBehaviour",
        state: state,
        idToken: idToken,
        accessToken: accessToken
      })
      sendMessageToWebsocket(payload)
    }
  }

  const addNewConversationBehaviour = () => {
    setConversationBehavioursEditStatus('saving')
    const payload = JSON.stringify({
      action: "addNewConversationBehaviour",
      idToken: idToken,
      accessToken: accessToken
    })
    sendMessageToWebsocket(payload)
  }



  return <div>
    <h2>Conversation state behaviours</h2>

    {conversationBehavioursEditStatus === 'saving' &&
      <div className={`${styles.modal}`} onClick={() => setConversationBehavioursEditStatus(null)}>
      <div className={`${styles.modalInner}`}>
        <div className={`${styles.savingPopup}`}>
          <img src='spinner.gif' alt='spinner' />
          <p>Processing...</p>
        </div>
        </div>
      </div>
    }



    {conversationBehaviours && Object.values(conversationBehaviours).map((item: any, index: any) => {
      const itemState = item.State
      return <div
        className={`${styles.behaviourTable} ${styles.card}`}
        key={index}
      >

        <div
          className={`${styles.columns}`}
        >
          <div>
            <h2>State: {itemState}</h2>
          </div>

          <div
            className={`${styles.columns} ${styles.gap10}`}
          >


            {conversationBehavioursEditStatus === item.State ?
              <>
                <button
                  className={`${styles.button}`}
                  onClick={() => deleteBehaviour(item.State)}
                >Delete</button>
                <div
                  className={`${styles.button} ${styles.primary}`}
                  onClick={() => saveBehaviour(item.State)}
                >

                  Save
              </div>

              </>

              :
              <button
                className={`${styles.button}`}
                onClick={() => setConversationBehavioursEditStatus(item.State)}
              >Edit</button>
            }
          </div>
        </div>
        {Object.keys(item).map((key, index) => {
          const fieldId = `${item.State}-${key}`
          if (key !== 'State') {
            return <div
              key={index}
              className={`${styles.columns} ${styles.gap10}`}
            >
              <p>{key}:</p>

              {conversationBehavioursEditStatus === item.State ?
                <input
                  type='text'
                  name={fieldId}
                  value={`${item[key]}`}
                  onChange={(e) => { handleChange(e.target.value, itemState, key) }}
                />
                :
                <p className={`${styles.fullwidth}`}>{`${item[key]}`}</p>
              }

            </div>
          }
        })}





      </div>
    })}


    <button
      className={`${styles.button} ${styles.primary}`}
      onClick={() => addNewConversationBehaviour()}
    >
      Add a new row
      </button>

  </div>
}
export default ConversationBehaviours