import React from 'react'
import { Link } from "react-router-dom"
import styles from './SplashPage.module.sass'

type Props = {
  loggedIn: string | boolean
}

function SplashPage({ loggedIn }: Props) {
  return <div className={`${styles.container} ${styles.centered}`}>
    <h2>SplashPage</h2>
    <Link to='/dashboard' className={`${styles.button} ${styles.primary}`}>Go to dashboard</Link>
  </div>
}
export default SplashPage