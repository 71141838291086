import React from 'react'
import { Link } from "react-router-dom"
import styles from './Header.module.sass'

type ObjectAny = {
  [key: string]: any
}

type Props = {
  loggedIn: Boolean | string
  logoutUrl: string
  setLoggedIn: React.Dispatch<React.SetStateAction<any>>
  setConnectionId: React.Dispatch<React.SetStateAction<any>>
  setOrganisations: React.Dispatch<React.SetStateAction<any>>
  setUserData: React.Dispatch<React.SetStateAction<any>>
  userData: ObjectAny | null
  currentOrganisation: string | null,
  switchCurrentOrganisation: (organisationId: string) => void
}

type LoggedInProps = {
  userData: ObjectAny | null
  handleLogout: () => void
  currentOrganisation: string | null,
  switchCurrentOrganisation: (organisationId: string) => void
}


function LoggedIn({
  userData,
  handleLogout,
  currentOrganisation,
  switchCurrentOrganisation
}: LoggedInProps) {


  const organisations = userData ? JSON.parse(userData.organisationsForUser) : {}

  return <div>

    <div className={`${styles.loggedIn}`}>
      <p>👤  {userData!.email}</p>
    </div>

    <div className={`${styles.switchOrg}`}>
      {
        organisations && Object.keys(organisations).length > 0 &&
        <select
          value={currentOrganisation ? currentOrganisation : ''}
          onChange={(e) => { switchCurrentOrganisation(e.target.value) }}
          className={`${styles.button} ${styles.mini}`}
        >
          {
            Object.keys(organisations).map((id, index) => {
              return <option
                key={index}
                value={id}
              >{organisations[id]}</option>
            })
          }
        </select>
      }
      <button onClick={handleLogout} className={`${styles.button} ${styles.primary} ${styles.mini}`}>logout</button>
    </div>
  </div>

}

function Header({
  loggedIn,
  logoutUrl,
  setLoggedIn,
  setConnectionId,
  setOrganisations,
  setUserData,
  userData,
  currentOrganisation,
  switchCurrentOrganisation
}: Props) {


  const handleLogout = () => {
    setLoggedIn(false)
    setUserData(null)
    setOrganisations(null)
    setConnectionId(null)
    localStorage.removeItem('localIdToken')
    localStorage.removeItem('localAccessToken')
    window.location.href = logoutUrl
  }


  return <header className={`${styles.fullwidth} ${styles.centeredcontent} ${styles.header}`}>
    <div className={`${styles.container} ${styles.headerColumns}`}>
     <img src='/alterd-logo.png' />

      {loggedIn === true && userData &&
        <LoggedIn
          userData={userData}
          handleLogout={handleLogout}
          currentOrganisation={currentOrganisation}
          switchCurrentOrganisation={switchCurrentOrganisation}
        />
      }

    </div>
  </header>
}
export default Header