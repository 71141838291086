import React, { useState, useEffect } from 'react'
import logo from './logo.svg'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
} from "react-router-dom"
import styles from './App.module.sass'
import CloudformationOutputs from './CloudformationOutputs.json'
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Dashboard from './components/Dashboard/Dashboard'
import SplashPage from './components/SplashPage/SplashPage'

type ObjectAny = {
  [key: string]: any
}

type Organisations = null | ObjectAny




function App() {

  const [loggedIn, setLoggedIn] = useState<boolean | string>(false)
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [connectionState, setConnectionState] = useState('disconnected')
  const [IOTsocket, setIOTSocket] = useState<WebSocket | null>(null)
  const [IOTconnectionState, setIOTConnectionState] = useState('disconnected')
  const [connectionId, setConnectionId] = useState<string | null>(null)
  const [userData, setUserData] = useState<ObjectAny | null>(null)
  const [organisations, setOrganisations] = useState<Organisations>(null)
  const [currentOrganisation, setCurrentOrganisation] = useState<string | null>(null)
  const [conversationBehaviours, setConversationBehaviours] = useState<ObjectAny | null>(null)
  const [conversationBehavioursEditStatus, setConversationBehavioursEditStatus] = useState<string | null>(null)
  const loginUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/login?client_id=${CloudformationOutputs.UserPoolClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${window.location.origin}/dashboard/`
  const logoutUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/logout?client_id=${CloudformationOutputs.UserPoolClientId}&logout_uri=${window.location.origin}/`

  const WebSocketURI = CloudformationOutputs.WebSocketURI
  const IOTWebSocketURI = CloudformationOutputs.IOTWebsocketURI

  useEffect(() => {
    setConnectionState('tryToConnect')
    setIOTConnectionState('tryToConnect')
  }, [])


  useEffect(() => {
    switch (connectionState) {
      case 'closed':
      case 'error':
        setConnectionState('waitThenReconnect')
        setTimeout(() => {
          setConnectionState('tryToConnect')
        }, 2000)
        break;
      case 'tryToConnect':
        setConnectionState('connecting')
        const newSocket = new WebSocket(WebSocketURI)
        newSocket.onopen = () => {
          setSocket(newSocket)
          setConnectionState('connected')
        }
        newSocket.onclose = () => {
          console.log("🔌  socket closed")
          setConnectionState('closed')
        }
        newSocket.onerror = () => {
          setConnectionState('error')
          console.log("🔌 socket error")
        }
        break;

    }

  }, [connectionState])




  useEffect(() => {
    switch (IOTconnectionState) {
      case 'closed':
      case 'error':
        setIOTConnectionState('waitThenReconnect')
        setTimeout(() => {
          setIOTConnectionState('tryToConnect')
        }, 2000)
        break;
      case 'tryToConnect':
        setIOTConnectionState('connecting')
        const newSocket = new WebSocket(IOTWebSocketURI)
        newSocket.onopen = () => {
          setIOTSocket(newSocket)
          setIOTConnectionState('connected')
        }
        newSocket.onclose = () => {
          console.log("🔌  IOT socket closed")
          setIOTConnectionState('closed')
        }
        newSocket.onerror = () => {
          setIOTConnectionState('error')
          console.log("🔌 IOT socket error")
        }
        break;

    }

  }, [IOTconnectionState])




  useEffect(() => {
    // handle incoming messages
    if (socket !== null) {
      actOnMessage()
    } else {
    }
  }, [socket])

  useEffect(() => {
    const selectedOrgId = window.localStorage.getItem('selectedOrgId')
    if (selectedOrgId) {
      setCurrentOrganisation(selectedOrgId)
    }
  }, [socket])


  const switchCurrentOrganisation = (organisationId: string) => {
    console.log(`Organization id change requested to ${organisationId}`)
    localStorage.setItem("selectedOrgId", organisationId)
    setCurrentOrganisation(organisationId)
  }

  const sendMessageToWebsocket = async (payload: string) => {
    if (socket) {
      //console.log(`sending to websocket: ${payload}`)
      socket.send(payload)
    } else {
      console.log('🚫 Can not send to web socket')
    }
  }

  const actOnMessage = () => {
    if (socket && socket.onmessage === null) {
      socket.onmessage = ({ data }: any) => {
        //console.log(`A message has been received: ${data}`)
        if (data) {
          const action = JSON.parse(data).action
          const payload = JSON.parse(data).payload
          switch (action) {
            case 'loggedIn':
              setLoggedIn(true)
              setConnectionId(JSON.parse(data).body.connectionId)
              setUserData(JSON.parse(data).body.idTokenDecoded.payload)
              setOrganisations(JSON.parse(JSON.parse(data).body.idTokenDecoded.payload.organisationsForUser))

              break
            case 'loginFailed':
              window.location.href = loginUrl
              break


            case 'conversationBehavioursUpdate':
              setConversationBehaviours(payload)
              setConversationBehavioursEditStatus(null)
              break


            case 'loginOKButNoOrganisation':
              window.localStorage.setItem('loggedInNoOrgId', data)
              window.location.href = '/noorg'
              break
            default:
              console.log(`A message has been received but the action is invalid. ${data}`)
              break
          }
        }
      }
    }
  }



  return <div className={`${styles.app}`}>
    <div className={`${styles.content}`}>
      <Header
        loggedIn={loggedIn}
        logoutUrl={logoutUrl}
        setLoggedIn={setLoggedIn}
        setConnectionId={setConnectionId}
        setOrganisations={setOrganisations}
        setUserData={setUserData}
        userData={userData}
        currentOrganisation={currentOrganisation}
        switchCurrentOrganisation={switchCurrentOrganisation}
      />


      <Router>
        <Routes>

          <Route
            path='/*'
            element={
              <SplashPage
                loggedIn={loggedIn}
              />
            } />

          <Route
            path='/dashboard'
            element={
              <Dashboard
                loggedIn={loggedIn}
                loginUrl={loginUrl}
                socket={socket}
                IOTsocket={IOTsocket}
                sendMessageToWebsocket={sendMessageToWebsocket}
                setLoggedIn={setLoggedIn}
                currentOrganisation={currentOrganisation}
                conversationBehaviours={conversationBehaviours}
                setConversationBehaviours={setConversationBehaviours}
                conversationBehavioursEditStatus={conversationBehavioursEditStatus}
                setConversationBehavioursEditStatus={setConversationBehavioursEditStatus}
              />
            } />

        </Routes>
      </Router>
    </div>
    <Footer />
  </div>
}

export default App
