import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.sass'
import { Link } from "react-router-dom"
import LoginRedirect from '../LoginRedirect/LoginRedirect'
import ConversationBehaviours from './ConversationBehaviours/ConversationBehaviours'

type ObjectAny = {
  [key: string]: any
}

type Props = {
  loggedIn: boolean | string
  loginUrl: string
  socket: WebSocket | null
  IOTsocket: WebSocket | null
  setLoggedIn: React.Dispatch<React.SetStateAction<string | boolean>>
  sendMessageToWebsocket: (payload: string) => void
  currentOrganisation: string | null
  conversationBehaviours: ObjectAny | null
  setConversationBehaviours: React.Dispatch<React.SetStateAction<any>>
  conversationBehavioursEditStatus: string | null
  setConversationBehavioursEditStatus: React.Dispatch<React.SetStateAction<any>>
}

function Dashboard({
  loggedIn,
  loginUrl,
  socket,
  setLoggedIn,
  sendMessageToWebsocket,
  currentOrganisation,
  conversationBehaviours,
  setConversationBehaviours,
  conversationBehavioursEditStatus,
  setConversationBehavioursEditStatus,
  IOTsocket
}: Props) {
  const [idToken, setIdToken] = useState<string | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)



  useEffect(() => {
    checkForToken()
  }, [])


  useEffect(() => {
    if (idToken && accessToken && socket) {
      // sending tokens to websocket!
      const payloadObj: { [index: string]: string } = {
        action: "login",
        idToken: idToken,
        accessToken: accessToken
      }
      const selectedOrgId = localStorage.getItem("selectedOrgId")
      if (selectedOrgId) {
        payloadObj['selectedOrgId'] = selectedOrgId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }
  }, [idToken, accessToken, socket, loggedIn, currentOrganisation])




  const checkForToken = () => {
    // Checking for a token
    const localIdToken = localStorage.getItem("localIdToken")
    const localAccessToken = localStorage.getItem("localAccessToken")
    const queryStringParams = window.location.hash.replace("#", "").split(/[&=]+/)

    let idToken = null
    let accessToken = null

    const indexOfIdToken = queryStringParams.indexOf('id_token')
    if (indexOfIdToken >= 0) {
      idToken = queryStringParams[indexOfIdToken + 1]
    }
    const indexOfAccessToken = queryStringParams.indexOf('access_token')
    if (indexOfAccessToken >= 0) {
      accessToken = queryStringParams[indexOfAccessToken + 1]
    }


    if (idToken && accessToken) {
      setLoggedIn('pending')
      setIdToken(idToken)
      setAccessToken(accessToken)
      localStorage.setItem('localIdToken', idToken)
      localStorage.setItem('localAccessToken', accessToken)
      window.location.href = '/dashboard'

    } else if (localIdToken && localAccessToken) {
      setIdToken(localIdToken)
      setAccessToken(localAccessToken)
    } else {
      window.location.href = loginUrl
    }
  }

  const makeFaceDoSomething = async (command: ObjectAny) => {
    if (IOTsocket) {
      //console.log(`sending to websocket: ${payload}`)
      IOTsocket.send(JSON.stringify(command))
    } else {
      console.log('🚫 Can not send to IOT web socket')
    }

  }

  return <div className={`${styles.container} ${styles.centered}`}>





    <h2>🔐 Dashboard</h2>

    <div className={`${styles.card} ${styles.faceBehaviours}`}>

      <h3>Face behaviours</h3>
      <button
        className={`${styles.button} ${styles.primary}`}
        onClick={() => makeFaceDoSomething({ "action": "eyebrows", "data": { "left": 0, "right": 0 } })}>
        Wiggle eyebrows</button>

      <hr />

      <h4>Frame lights</h4>
      <button
        className={`${styles.button} ${styles.primary}`}
        onClick={() => makeFaceDoSomething({ "action": "frameLights", "data": { "mode": "ALL_OFF", "brightness": 32768, "delay": 1000 } })}>
        All off</button>
      <button
        className={`${styles.button} ${styles.primary}`}
        onClick={() => makeFaceDoSomething({ "action": "frameLights", "data": { "mode": "ALL_ON", "delay": 500 } })}>
        All on</button>
      <button
        className={`${styles.button} ${styles.primary}`}
        onClick={() => makeFaceDoSomething({ "action": "frameLights", "data": { "mode": "CHASE", "delay": 500 } })}>
        Chase</button>
      <button
        className={`${styles.button} ${styles.primary}`}
        onClick={() => makeFaceDoSomething({ "action": "frameLights", "data": { "mode": "CHASE_2", "delay": 300 } })}>
        Chase 2</button>
      <hr />

      <h4>LED strip</h4>
      <button
        className={`${styles.button} ${styles.primary}`}
        onClick={() => makeFaceDoSomething({ "action": "ledStrip", "data": { "r": 255, "g": 0, "b": 0, "fade": 500 } })}>
        Red fade 500</button>
      <button
        className={`${styles.button} ${styles.primary}`}
        onClick={() => makeFaceDoSomething({ "action": "ledStrip", "data": { "r": 0, "g": 255, "b": 0, "fade": 500 } })}>
        Green fade 500</button>
      <button
        className={`${styles.button} ${styles.primary}`}
        onClick={() => makeFaceDoSomething({ "action": "ledStrip", "data": { "r": 0, "g": 0, "b": 255, "fade": 500 } })}>
        Blue fade 500</button>
      <button
        className={`${styles.button} ${styles.primary}`}
        onClick={() => makeFaceDoSomething({ "action": "ledStrip", "data": { "r": 255, "g": 255, "b": 255 } })}>
        White no fade</button>

    </div>


    {loggedIn === true &&
      <ConversationBehaviours
        socket={socket}
        sendMessageToWebsocket={sendMessageToWebsocket}
        conversationBehaviours={conversationBehaviours}
        setConversationBehaviours={setConversationBehaviours}
        idToken={idToken}
        accessToken={accessToken}
        conversationBehavioursEditStatus={conversationBehavioursEditStatus}
        setConversationBehavioursEditStatus={setConversationBehavioursEditStatus}
      />

    }


    {loggedIn === 'pending' &&
      <>
        <img src="/spinner.gif" width='50' />
        <p>logging you in....</p>
      </>
    }

    {loggedIn === false &&
      <LoginRedirect
        loginUrl={loginUrl
        } />
    }
  </div>
}
export default Dashboard